<template>
  <Modal v-if="!isIframe" :onClose="onClose" class="z-11000">
    <div class="root">
      <div  class="flex flex-grow">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===1" src="../assets/images/audioOnboarding/1.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===2" src="../assets/images/audioOnboarding/2.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===3" src="../assets/images/audioOnboarding/3.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===4" src="../assets/images/audioOnboarding/4.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===5" src="../assets/images/audioOnboarding/5.png">
        <img class="rounded-tl-lg rounded-bl-lg leftImg" v-show="step===6" src="../assets/images/audioOnboarding/6.png">

        <div class="flex flex-col justify-between w-full p-6">
          <div v-show="step===1">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Welcome to Spext
            </h2>

            <div class="mt-4 text-xs text-primary-800">
              It is like a text editor to edit media.<br>
              Delete portions of the transcript to delete <br>
              the media
            </div>
          </div>

          <div v-show="step===2">
            <h2 class="text-2xl font-semibold font-h text-primary">
              You can
            </h2>

            <div class="mt-4 text-xs text-primary-800">
              <div class="flex items-start mb-3">
                <i class="mr-3 text-base text-green far fa-check-circle" />
                <div>Delete spoken words/sentences and “uhs”, “ums”, etc</div>
              </div>
              <div class="flex items-start">
                <i class="mr-3 text-base text-green far fa-check-circle" />
                <div>Delete or add silence (shown as <span style="color:#DAD6DE;">-----------</span>)</div>
              </div>
            </div>
          </div>

          <div v-show="step===3">
            <h2 class="text-2xl font-semibold font-h text-primary">
              You cannot
            </h2>

            <div class="mt-4 text-xs text-primary-800">
              <div class="flex items-start mb-3">
                <i class="mr-3 text-base text-red far fa-times-circle" />
                <div>Add or change spoken words</div>
              </div>
              <div class="flex items-start">
                <i class="mr-3 text-base text-red far fa-times-circle" />
                <div>Create a new spoken sentence</div>
              </div>
            </div>
          </div>

          <div v-show="step===4">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Rearranging Portions
            </h2>

            <div class="mt-4 text-xs text-primary-800">
              Just like text editors, you can use
              <div class="mt-4 mb-4 font-bold">⌘+X - ⌘+V</div>
              to rearrange portions of the media It’s that <br/>easy!
            </div>
          </div>

          <div v-show="step===5">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Select transcript to add music, clip portions, etc.
            </h2>

            <div class="mt-4 text-xs">
              <img src="../assets/images/audioOnboarding/51.png" class="mt-4">
            </div>
          </div>

          <div v-show="step===6">
            <h2 class="text-2xl font-semibold font-h text-primary">
              Tip: Use Plus Button for more interactions
            </h2>

            <div class="mt-4 text-xs">
              <img src="../assets/images/audioOnboarding/61.png" class="mt-4">
            </div>
          </div>

          <div class="flex items-center justify-end">
            <div v-show="prevStep>=0">
              <Button size="small" variant="secondary" :onClick="goToPrev" classname="mr-2.5">Previous</Button>
            </div>

            <div v-show="nextStep>0 && nextStep<stepsOrder.length">
              <Button size="small" :onClick="goToNext" classname="pr-1.5">
                Next <ChevronRight classname="h-3 w-3 ml-0.75"/>
              </Button>
            </div>

            <div v-show="nextStep===stepsOrder.length">
              <Button size="small" :onClick="close">
                <span class="mr-0.5">Got it</span>
                <i class="twa twa-thumbsup"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'
require('../assets/css/twemoji-awesome.css')
export default {
  components: {
    Modal,
    Button,
    ChevronRight
  },
  data () {
    return {
      step: 1,
      stepsOrder: [1, 2, 3, 4, 5, 6], // this show the order of screens to show, use this to modify it
      isActive: false,
      isIframe: (window.location !== window.parent.location)
    }
  },
  computed: {
    prevStep () {
      let index = this.stepsOrder.indexOf(this.step)
      if (index < 0) return -1
      return index - 1
    },
    nextStep () {
      let index = this.stepsOrder.indexOf(this.step)
      if (index < 0) return -1
      return index + 1
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    onClose: function() {
      localStorage['onBoardingEditMedia'] = 'shown'
      window.analytics.track('popup_onboardingComplete', { type: 'audio' })
      window.Intercom('startTour', 58545)
    },
    close: function() {
      this.closeModal()
      this.onClose()
    },
    goToPrev () {
      if (this.prevStep < 0) return
      this.step = this.stepsOrder[this.prevStep]
    },
    goToNext () {
      if (this.nextStep < 0) return
      this.step = this.stepsOrder[this.nextStep]
    }
  }
}
</script>

<style lang="less" scoped>
.root {
  width: 560px;
  height: 272px;
}

.leftImg  {
  height: 272px;
  width: 210px;
}
</style>
